.swiper-button-next,
.swiper-button-prev {
    border-radius: 9px;
    width: 30px;
    height: 30px;
    color: rgb(255, 255, 255);
}

.swiper-button-next::after,
.swiper-button-prev::after {
    text-shadow: 0 0 5px #333;
    font-size: 25px;
    font-weight: bold;
}

.thumb-swiper .ui.image img {
    width: 100%;
    height: 100%;
    object-fit: contain;
}

.thumb-swiper.swiper-thumbs .swiper-wrapper {
    flex-wrap: wrap;
    justify-content: center;
    gap: 8px;
}

.thumb-swiper.swiper-thumbs .swiper-slide {
    box-sizing: border-box;
    width: 30px !important;
}
.swiper-slide-thumb-active .ui.image {
    border: 2px solid black
}