/* fix for iOS */
a[type="button"] {
  appearance: none;
}

.binorange, i.icon.binorange {
  color: #ff783c !important;
}
.atmgreen, i.icon.atmgreen {
  color: #5fac62 !important;
}
.alert {
  color: #ff0000 !important;
}

.bins-map {
  display: block;
  border: 1px solid lightgray;
  width: 100%;
  height: 40rem;
}

/* reverse override of font family in leaflet CSS */
.bins-map.leaflet-container {
  font-family: inherit;
}

.narrow-list .header, .narrow-list .description {
  overflow: hidden;
  text-overflow: ellipsis;
}

.narrow-list .item > .content *:not(:last-child) {
  margin-bottom: .5em;
}

.ui.list.narrow-list {
  margin-bottom: 0;
}

@media only screen and (max-width: 767px) {
  .bins-map {
    height: 75vh;
  }
}

/* TODO fix this hack */
.header-menu {
  background-color: #f0f5f0 !important;
}

@media only screen and (max-width: 767px) {
  .header-menu .ui.container {
    margin-left: .57142857em !important;
    margin-right: .57142857em !important;
  }
  .ui.vertical.menu.header-menu {
    width: auto;
  }
}

.header-menu:not(.vertical) .grid .column {
  padding-left: 0 !important;
  padding-right: 0 !important;
}
.menu.header-submenu {
  border-color: transparent !important;
  box-shadow: none !important;
  border-radius: 0;
}
.header-menu .item, .header-submenu .item {
  font-weight: 500 !important;
  text-transform: uppercase !important;
  color: rgba(0,0,0,.87) !important;
}
.menu.header-menu:not(.vertical) .item {
  text-align: center;
  padding: 0 0 !important;
  margin-right: 0 !important;
  margin-left: .71428572em !important;
}
.menu.header-menu:not(.vertical) .item:not(:last-child)::after {
  content: '';
  height: 2em;
  width: .71428572em;
  border-right: 1px solid #f67643;
}
.header-menu .item:not(.header):hover, .header-menu .item.active {
  color: #22aa86 !important;
  background: transparent !important;
}

.header-submenu .item {
  color: rgba(255,255,255,.8) !important;
}
.header-submenu .item:not(.header):hover, .header-submenu .item.active {
  color: white !important;
}

.header-admin-menu {
  background-color: grey !important;
  margin: 0 !important;
}
.header-user-menu {
  background-color: #77b0bd !important;
  margin: 0 !important;
}
.header-not-logged-menu {
  background-color: #77b0bd  !important;
  margin: 0 !important;
}
.header-user-menu .item {
  font-weight: bold !important;
}

.header-title {
  color: white !important;
  background-color: #77b0bd !important;
}
.ui.segment.header-title {
  margin-bottom: 0;
}

.signup-bt {
  background-color: #06586B !important;
  color: #fff !important;
}

.signup-bt:hover {
  background-color: #196d80 !important;
}

:root {
  --header-height: 19rem;
  --footer-height: 32rem;
}

/* tablet */
@media screen and (min-width: 768px) and (max-width: 991px) {
  :root {
    --header-height: 25rem;
    --footer-height: calc(32rem + 15rem);
  }
}

/* mobile */
@media screen and (max-width: 768px) {
  :root {
    --header-height: 23rem;
    --footer-height: calc(32rem + 50rem);
  }
}

.header-content {
  min-height: var(--header-height);
}

.footer-menu {
  background-color: #4c5953 !important;
  margin: 0 !important;
}

main {
  min-height: calc(100vh - var(--footer-height) - var(--header-height));
  /* WTF */
  padding: 1px 0; 
}


/* fix sticky showing on bottom of page in mobile dashboard view */
/* this is needed to fix erratic behavior of Sticky stuff if they are part of children */
/* see https://github.com/Semantic-Org/Semantic-UI-React/issues/2897 */
.pushable {
  transform: none !important;
}
.ui.bound.sticky {
  display: none;
}


.ui.grid.stackable.compact:not(.stretched) .column {
  padding-left: 0px !important;
  padding-right: 0px !important;
}

.ui.grid.stackable.compact.stretched .column {
  padding-top: 0px !important;
  padding-bottom: 0px !important;
}

.ui.grid.stackable.compact.stretched {
  padding-top: 1rem;
  padding-bottom: 1rem;
}

.orange.divider {
  background-color: #F67643 !important;
  border-top: 5px solid #F67643 !important;
  border-bottom: 4px solid #F67643 !important;
  margin-bottom: -5px;
  z-index: 1;
  position: relative;
}

.fat.divider {
  background-color: #707070 !important;
  border-color: #707070 !important;
  border-top-width: 2px !important;
  border-bottom-width: 2px !important;
}

.ui.header.action-header {
  color: white !important;
  text-transform: uppercase;
  padding-left: 2rem;
  border-width: 0px !important;
  border-radius: 5px;
  display: block;
}
.ui.header.action-header .icon {
  float: right;
  margin: 0;
}
a.ui.header.action-header:hover {
  color: white !important;
  cursor: pointer;
  user-select: none;
  box-shadow: 0 2px 4px 0 rgba(34,36,38,.12),0 2px 10px 0 rgba(34,36,38,.15);
}
.action-header.blue {
  background-color: #2088be !important;
}
.action-header.green {
  background-color: #5fac62 !important;
}
.action-header.orange {
  background-color: #ff783c !important;
}

.ui.segment.attached.colored {
  border-width: 0;
}
.ui.segment.lightgreen, .ui.container.lightgreen {
  background-color: #edf5ed;
}
.ui.segment.brightgreen {
  background-color: #6cc46f;
}

.ui.list.decorated > .item > span {
  display: inline-block;
  margin-left: 1em;
}
.ui.list.decorated.bulleted > .item::before {
  font-size: x-large;
}

.ui.list.decorated:not(.bulleted) {
  margin-left: 1em;
}
.ui.list.decorated:not(.bulleted) > .item::before {
  vertical-align: top;
  margin-left: -1em;
}

.ui.list.green > .item::before {
  color: #5fac62;
}
.ui.list.orange > .item::before {
  color: #ef8048;
}
.ui.list.orange > a.item::before {
  color: #ef8048;
}
.ui.list.red > .item::before {
  color: #c91010;
}

.ui.list.icon > .item::before {
  font-size: larger;
	font-family: Icons;
	font-style: normal;
	font-weight: 400;
	text-align: center;
}
.ui.list.icon.check > .item::before {
  content: '\f00c';
}
.ui.list.icon.close > .item::before {
  content: '\f00d';
}

.ui.header.colored {
  text-transform: uppercase;
}

.ui.segment.small .ui.image {
  height: 30vh;
}


.ui.accordion.green .title {
  color: #4d4d4d;
  background-color: #e0e0e0;
  margin: 2px 0;

  padding: .75em 3em .75em 2em;

  line-height: 1.25;
  font-size: 1.5rem;
  font-weight: 500;
  position: relative;
}

.ui.accordion.green .title.active {
  color: white;
  background-color: #5fac62;
}

.ui.accordion.green .title .icon:first-child {
  display: none;
}

.ui.accordion.green .title::before {
  content: '\f078';
  font-family: Icons;
  font-size: x-large;

  position: absolute;
  right: 1em;
}

.ui.accordion.green .title.active::before {
  content: '\f077';
}


.green-icon {
  color: #72f3b9;
}

.green-icon.t::after {
  font-family: 'Courier New', Courier, monospace;
  font-weight: bolder;
  content: 'T'
}

.green-icon.f::after {
  font-family: 'Courier New', Courier, monospace;
  font-weight: bolder;
  content: 'F'
}

.footer-header {
  text-transform: uppercase !important;
  font-weight: 400 !important;
  position: relative;
  margin-bottom: 24px !important;
}

.footer-header::before {
	background-color: #22AA86;
	content: '';
	width: 48px;
	height: 2px;
	border-bottom: 2px;
	text-align: inherit;
	bottom: -12px;
	position: absolute;
}

.footer-header::after {
  content: '';
	width: calc(100% - 52px);
	height: 2px;
	border-bottom: 2px solid rgba(255,255,255,.16);
	text-align: inherit;
	bottom: -12px;
	position: absolute;
	right: 0;
}

.footer-links a {
  text-transform: uppercase !important;
  font-weight: 400;
}

.ui.inverted.list.footer-links  {
	color: rgba(255,255,255,.90) !important;
}

.ui.inverted.list.footer-links .item a:not(.ui):hover {
	color: #fff !important;
}

.ui.list.decimal > .item:before {
  content: counters(ordered, ".") ". ";
}
.ui.list.decimal .list > .item:before {
  margin-left: -1.25rem;
}
.ui.list.bulleted > .item:before {
  content: "\2022" !important;
  /* margin-left: 1.25rem !important; */
}

.dashlist {
  list-style-type: '- ';
  list-style-position: inside;
  padding: 0;
}

.ck-content img {
  max-width: 100%;
}

.ck-content .image {
  text-align: center;
  margin-left: auto;
  margin-right: auto;
}

.ck-content .image-style-side {
  float: right;
  margin-right: 1.5em;
  max-width: 50%;
}

.ui.vertical.pointing.menu .item {
  text-align: center;
  line-height: inherit !important;
  padding: .92857143em .78571429em !important;
}

.ui.vertical.pointing.pagination.menu .item {
  padding: .78571429em .78571429em !important;
}

.ui.vertical.left.pointing.menu .item:after {
  right: auto;
  left: 0;
  transform: translateX(-50%) translateY(-50%) rotate(45deg);
  margin: 0 0 0 -.5px;
  border: none;
  border-bottom: 1px solid #d4d4d5;
  border-left: 1px solid #d4d4d5;
}

.ui.vertical.left.pointing.menu .item.disabled.active {
  background-color: rgba(0, 0, 0, .05) !important;
}

.ui.vertical.menu .item > i.icon {
  float: none !important;
  margin: 0;
}

@media only screen and (max-width: 768px) {
  .ui.compact.grid {
    padding-top: 1rem !important;
    padding-bottom: 1rem !important;
  }
  .ui.compact.grid > .column:not(.row) {
    padding: 3px 1rem !important;
  }
  .ui.menu {
    min-height: auto;
  }
}

.ui.compact.menu .item:last-child {
  border-radius: 0;
}

.ui.selectable.pointable.table tbody tr:hover {
  cursor: pointer;
}

/* Webfont: Lato-Bold */@font-face {
  font-family: 'Lato';
  src: url('fonts/Lato-Bold.woff2') format('woff2');
  font-style: normal;
  font-weight: bold;
  text-rendering: optimizeLegibility;
}

/* Webfont: Lato-BoldItalic */@font-face {
  font-family: 'Lato';
  src: url('fonts/Lato-BoldItalic.woff2') format('woff2');
  font-style: italic;
  font-weight: bold;
  text-rendering: optimizeLegibility;
}

/* Webfont: Lato-Italic */@font-face {
  font-family: 'Lato';
  src: url('fonts/Lato-Italic.woff2') format('woff2');
  font-style: italic;
  font-weight: normal;
  text-rendering: optimizeLegibility;
}

/* Webfont: Lato-Regular */@font-face {
  font-family: 'Lato';
  src: url('fonts/Lato-Regular.woff2') format('woff2');
  font-style: normal;
  font-weight: normal;
  text-rendering: optimizeLegibility;
}

/* Webfont: Lato-Medium */@font-face {
  font-family: 'Lato';
  src: url('fonts/Lato-Medium.woff2') format('woff2');
  font-style: normal;
  font-weight: 500;
  text-rendering: optimizeLegibility;
}

/* Webfont: Lato-MediumItalic */@font-face {
  font-family: 'Lato';
  src: url('fonts/Lato-MediumItalic.woff2') format('woff2');
  font-style: italic;
  font-weight: 500;
  text-rendering: optimizeLegibility;
}

.underline-on-hover:hover{
  text-decoration: underline;
}
.centered {
  margin: 0 auto;
}
.centered-text{
  text-align: center;
}
.intro-steps {
  max-width: max-content;
  margin: 16px auto;
  text-align: left;
  padding: 16px;
  border-radius: 16px;
  background-color: rgba(33, 186, 69, 0.1);
}
.intro-steps .list{
  font-size: large;
}
.intro-steps .list a:hover{
  text-decoration: underline;
}
.padded-left{
  padding-left: 24px;
}

.blue-bg {
  height: 100%;
  background-color: #5db2bc !important;
  max-height: 200px;
}
.blue-bg.big-height{
  max-height: 250px;
  background-color: #5db2bc41 !important;
  padding: 0 !important;
  min-height: 200px;
}
.blue-bg .ui.image {
  height: 100%;
  width: 100%;
}
.blue-bg .ui.image > img {
  width: 100%;
  height: 100%;
  object-fit: contain;
}

.custom-card{
  margin: 0 !important;
}
.custom-content{
  min-height: 72px !important;
  flex-grow: 1 !important;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}

.more-bt{
flex-grow: 0 !important;
}
.custom-grid{
  display: grid;
  gap: 16px;
  grid-template-columns: repeat(auto-fit, minmax(250px, 1fr));
  justify-items: center;

}

.nowrap {
  width: 33% !important;
}

.nowrap .ui.selection.dropdown>.text {
  text-overflow: ellipsis;
  white-space: nowrap;
  width: 100%;
  overflow: hidden;
}

@media screen and (max-width: 768px) {
  .nowrap {
    width: 100% !important;
  }
}

.ellipsis .ui.selection.dropdown>.text, .ellipsis>.text{
  width: 100%;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}


.upload-photo .ui.input input {
  opacity: 0;
  position: absolute;
  z-index: -1;
}

.no-margin-form {
  margin-top: 1px;
}
.no-margin-form>.fields {
  margin: 0 !important;
}

.material-container {
  display: flex;
  justify-content: space-around;
  gap: 24px;
}
@media only screen and (max-width: 767px) {
  .material-container {
    flex-direction: column;
  }
}
.ui.material-description>.item::after {
  display: none;
}
.ui.ui.material-description {
  width: min(400px, max(100%,  400 / 1200 * 100vw));
  margin: 0;
  background-color: #80808012;
  padding: 12px 8px;
  border-radius: 8px;
}
.ui.ui.material-description.transparent {
  background-color: transparent;
  padding: 0;
}
.ui.ui.material-description.full-width {
  width: 100%;
}
.ui.material-description .item {
  display: flex;
  justify-content: space-between;
  align-items: center;
  gap: 16px;
}

.ui.material-description .item:has(input) {
  padding-top: 2px !important;
  padding-bottom: 2px !important;
}

.ui.material-description .item .content:has(.search) {
  width: 100%;
}

.ui.material-description .item:has(input):is(:last-child) {
  margin-bottom: -10px;
}

.ui.material-description .item:has(input):is(:first-child) {
  margin-top: -10px;
}

.field.highlighted .ui.input >input {
  border-color: yellow;
  background: #ffff0024;
}

.ui.list.padded>.item .header {
  margin-bottom: 8px;
}

.ui.image.kdey-image {
  display: block;
  max-height: 250px;
  height: 100%;
}

.ui.image.kdey-image>img {
  width: 100%;
  height: 100%;
  object-fit: contain;
}

.kdey-map {
  display: block;
  border: 1px solid lightgray;
  width: 100%;
  height: 250px;
}

.ui.dropdown.wrap .menu>*{
  white-space: normal;
}

.grey-bg-ct {
  width: 100%;
  margin: 0;
  background-color: #80808012;
  padding: 12px 8px;
  border-radius: 8px;
}

.three-cols-grid {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  column-gap: 16px;
  row-gap: 24px;
}

.two-cols-grid {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  column-gap: 16px;
  row-gap: 24px;
}

@media screen and (min-width: 768px) and (max-width: 991px) {
  .three-cols-grid {
    grid-template-columns: repeat(2, 1fr);
  }  
  
}

@media screen and (max-width: 768px) {
  .three-cols-grid {
    grid-template-columns: repeat(1, 1fr);
  }
}

.ui.structured.celled.table tr td, .ui.structured.celled.table tr th {
  border-bottom: 1px solid rgba(34,36,38,.1);
}

.text-ellipsis {
  overflow: hidden;
  text-overflow: ellipsis;
  -webkit-line-clamp: 3;
  line-clamp: 3; 
  display: -webkit-box !important;
  -webkit-box-orient: vertical;
}